.photo-frame {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  width: 100%;
  height: 100%;
  position: relative;
}

.photo-container {
  flex:1;
  background-color: red;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: row;
  text-shadow: black 0 0 0.4em;
}

.photo {
  max-width: 100%;
  position: relative;
  z-index: 0;
}

.photo-annotations {
  position: absolute;
  z-index: 10;
}

.photo-background {
  background-color: #fff;
}


.photo-toolbar {
  position: absolute;
  z-index: 10;
  top: 1rem;
  right: 1rem;
  color: #fff;
}

.photo-info {
  padding: 1rem;
  color: #fff;
  overflow-y: auto;
  background-color: #000;
  flex:2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.photo-info-description{
  max-height: 40%;
  overflow-y: auto;
  text-align: justify;
}

.photo-image {
  flex:3;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.photo-info-overlay{
  position: absolute;
  z-index: 10;
  color: #fff;
  top: 0;
  left: 0;
  padding: 0.5rem;
  max-height: 70%;
  overflow-y: auto;
}

.photo-objects{
  height: 50px;
  background-color: #fff;
  width: 100%;
}


.photo-calendar-info {
  position: absolute;
  z-index: 10;
  bottom: .5rem;
  left: .5rem;
  color: #fff;
}

.photo-location-info {
  position: absolute;
  z-index: 10;
  bottom: .5rem;
  right: .5rem;
  color: #fff;
}


.text-small {
  font-size: 0.85em;
  font-weight: 200;
}

.icon-active {
  color: skyblue;
}

.photo-annotation-circle{
  stroke: hotpink;
  stroke-width: 2;
  fill: transparent;
  cursor: pointer;
}

.popovers {
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  /* background-color: rgba(0,0,0,.34); */
}

.photo-popover {
  position:absolute;
  width:auto;
  height: auto;
  background:#fff;
  color: #222;
  padding: 0.5rem;
}
