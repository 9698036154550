@import "~bootstrap/scss/bootstrap";
@import "variables";
@import "fonts";
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";
// @import "fonts";

body {
  font-family: $monospace !important;
  // padding-top: 70px;
}

// general utilities
.h-100vh {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

}

.navbar-brand {
    color: rgba(0,0,0,0.5) !important;
    @media screen and (max-width: $small) { 
        // font-size: 80% !important;
    }
}

.navbar-toggler {
    border: none !important;
}

.min-100vh{
    min-height: 100%
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}
.pointer {
  cursor: pointer;
}

.no-link-style {
  color: inherit;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

.with-topbar {
  padding-top: 54px;
}

.with-footer {
  padding-bottom: 54px;
}

.home-links{
  // background-color: rgba(255,255,255,0.24)
}

.home-text{
  text-shadow: 4px 2px 14px $light;

}

.home-cover {
  // background-image: url(/quadrati/15.jpg);
//   transition: background-image 4s;
//   transition: opacity 1s;
//   background-size: 100%;
//   background-position: center;
//   background-repeat: no-repeat;

}
 
.home-slider {
    margin: 0;
    padding: 0;
    .slick-list {
        flex: 1;
        display: flex;
        flex-direction: column;

        .slick-track {
            flex: 1;
        }
    }
} 

.home-container {
    position: relative;
    
}

.home-slides {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
}

.home-texts {
    position: absolute;
    z-index: 2;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding-bottom: 50px;
    
    

    .big-name {
        // mix-blend-mode: difference;
        background: transparent;
        cursor: pointer;
        
        font-size: 6em;
        line-height: 1em;
        @media screen and (max-width: $small) { 
            font-size: 4em;
        }

        -webkit-text-stroke: 1.5px rgba(255,255,255,0.7);
        transition: -webkit-text-stroke 0.6s ease-in-out;

        &:hover {
            // -webkit-text-stroke: 2px rgba(255,255,255,1);
        }

        
        color: transparent;
        // text-transform: uppercase;
        
        padding: 1em 0;

        a {
            transition: color 0.6s ease-in-out;
            -webkit-text-stroke: inherit;
            text-decoration: none;
            color: transparent;
        }

        a:hover {
            color: #fff;
            -webkit-text-stroke: 2px rgba(0,0,0,0.8);
        }
        
    }

        
        
    
}


.footer-small {
  font-size: 10px;
}

.collection-link {
  color: inherit;
  &:hover {
    color: $primary;
    text-decoration: none;
  }
}


.artwork-mini{
  width: 110px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    object-fit: cover;
    width: 100px;
    height: 100px;
  }
}


.artwork-full  {
  height: calc(100% - 140px);
  // flex: 1;
  // max-height: 100%;
}
.artwork-info {
  position: relative;

  h3 {
    @media screen and (max-width: $small) { 
        font-size: 18px;
    }
  }

  .artwork-arrow-left {
      cursor: pointer;
      position: absolute;
      z-index: 10;
      top: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    
  }
  .artwork-arrow-right {
    cursor: pointer;
    position: absolute;
    z-index: 10;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .info {
      z-index: 1;
      position: relative;
      padding: 0 30px;
      
  }
}