.photoset {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.photoset-row {
  display: flex;
  position: relative;
}

.photoset-cell {
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
}

.photoset-title-hover {
  position: absolute;
  z-index: 1;
  bottom:0;
  background-color: rgba(0,0,0,.25);
  padding: 0.5em;
  font-size: 14px;
  text-align: center;
  color: #fff;
}

.photoset-title {
  padding: 0.5em;
  font-size: 14px;
  text-align: center;
}
